import http from './http';
import store from '../store';
import { getApiRoute } from '../../config';

export default {
  async getUpcomingEvents() {
    const config = store.getters.getGameGetter({ getter: 'config', spcificGame: 'crashcash' });
    const upcomingEvents = await http.get(
      `${getApiRoute('historyURL', { productName: 'crashcash' })}${getApiRoute('upcomingEvents', {
        productName: 'crashcash',
      })}${config.productInstanceUuid}`,
    );
    return upcomingEvents.data;
  },
};
