import http from './http';
import { getApiRoute } from '../../config';

export default {
  async getCodes(headers) {
    const response = await http.get(`${getApiRoute('webSocketUrl', { productName: 'crashcash' })}/grant-codes`, {
      headers,
    });

    return response.data;
  },
};
