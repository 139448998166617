export default {
  crashcash: {
    development: {
      baseURL: 'https://games-crash-cash-ticket.staging.de-2.nsoft.cloud',
      historyURL: 'https://games-crash-cash-event.staging.de-2.nsoft.cloud',
      upcomingEvents: '/upcoming/',
      gameResults: '/results/game/',
      ticketPayin: '/seven/tickets/',
      ticketPayout: '/tickets',
      ticketCancel: '/tickets',
      ticketBarcodeCheck: '/tickets/barcode/',
      ticketRequestIdCheck: '/tickets/',
      webSocketUrl: 'https://crash-cash-websocket.staging.de-2.nsoft.cloud',
    },
    staging: {
      baseURL: 'https://games-aggregator.staging.de-2.nsoft.cloud',
      historyURL: 'https://games-crash-cash-event.staging.de-2.nsoft.cloud',
      upcomingEvents: '/upcoming/',
      gameResults: '/results/game/',
      ticketPayin: '/seven/tickets/',
      ticketPayout: '/seven/tickets/actions/payout',
      ticketCancel: '/seven/tickets/actions/cancel',
      ticketBarcodeCheck: '/seven/tickets/barcode',
      ticketRequestIdCheck: '/seven/tickets',
      webSocketUrl: 'https://crash-cash-websocket.staging.de-2.nsoft.cloud',
    },
    production: {
      baseURL: 'https://games-aggregator.de-2.nsoft.cloud',
      historyURL: 'https://games-crash-cash-event.de-2.nsoft.cloud',
      upcomingEvents: '/upcoming/',
      gameResults: '/results/game/',
      ticketPayin: '/seven/tickets/',
      ticketPayout: '/seven/tickets/actions/payout',
      ticketCancel: '/seven/tickets/actions/cancel',
      ticketBarcodeCheck: '/seven/tickets/barcode',
      ticketRequestIdCheck: '/seven/tickets',
      webSocketUrl: 'https://crash-cash-websocket.de-2.nsoft.cloud',
    },
  },
  ngsMonolith: {
    development: {
      baseURL: 'https://menhir.staging.gb.nsoftcdn.com/shop',
      historyURL: 'https://games.staging.gb.nsoftcdn.com/app.php',
      gameResults: '/api_open/retail/events.json',
      ticketPayin: '/tickets.json',
      ticketPayout: '/tickets',
      ticketCancel: '/tickets',
      ticketBarcodeCheck: '/tickets/check/',
      ticketRequestIdCheck: '/tickets/request/',
    },
    staging: {
      baseURL: 'https://menhir.staging.gb.nsoftcdn.com/shop',
      historyURL: 'https://games.staging.gb.nsoftcdn.com/app.php',
      gameResults: '/api_open/retail/events.json',
      ticketPayin: '/tickets.json',
      ticketPayout: '/seven/tickets/actions/payout',
      ticketCancel: '/seven/tickets/actions/cancel',
      ticketBarcodeCheck: '/tickets/check/',
      ticketRequestIdCheck: '/tickets/request/',
    },
    production: {
      baseURL: 'https://menhir.gb.nsoftcdn.com/shop',
      baseURL_DE: 'https://menhir.de.nsoftcdn.com/shop',
      baseURL_RO2: 'https://menhir.ro-2.nsoftcdn.com/shop',
      baseURL_RO: 'https://menhir.ro.nsoftcdn.com/shop',
      baseURL_RS2: 'https://menhir.rs-2.nsoftcdn.com/shop',
      baseURL_RS: 'https://menhir.rs.nsoftcdn.com/shop',
      historyURL: 'https://games.gb.nsoftcdn.com/app.php', // TODO
      historyURL_DE: 'https://games.de.nsoftcdn.com/app.php',
      historyURL_RO2: 'https://games.ro.nsoftcdn.com/app.php',
      historyURL_RO: 'https://games.ro.nsoftcdn.com/app.php',
      historyURL_RS2: 'https://games.gb.nsoftcdn.com/app.php',
      historyURL_RS: 'https://games.gb.nsoftcdn.com/app.php',
      gameResults: '/api_open/retail/events.json',
      ticketPayin: '/tickets.json',
      ticketPayout: '/seven/tickets/actions/payout',
      ticketCancel: '/seven/tickets/actions/cancel',
      ticketBarcodeCheck: '/tickets/check/',
      ticketRequestIdCheck: '/tickets/request/',
    },
  },
};
