/*
Use this file to parse and set your configurations.
You can use process.env to get enviroment variables set in env files.
*/
import apiRoutes from './routes';

const env = process.env.VUE_APP_ENVIRONMENT || process.env.NODE_ENV;
const routes = {
  crashcash: 'crashcash',
  default: 'ngsMonolith',
};

const getApiRoute = (routeName, config = {}) => {
  const { productName, platformDataCenter } = config;
  const normalizedProduct = productName?.toLowerCase();
  const route = routes[normalizedProduct] || routes.default;
  const envRoutes = apiRoutes[route]?.[env];
  const isProduction = env === 'production';

  // If no routes exist for the given product and environment, return null
  if (!envRoutes) {
    return null;
  }

  // Default route value
  let apiRoute = envRoutes[routeName];

  // Handle URLs overrides (baseURL_RO, historyURL_DE, etc.) in production
  if (isProduction && platformDataCenter) {
    const platformKey = `${routeName}_${platformDataCenter.toUpperCase()}`;
    if (envRoutes[platformKey]) {
      apiRoute = envRoutes[platformKey];
    }
  }
  return apiRoute;
};

export { env, routes, getApiRoute };
