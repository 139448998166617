import http from './http';
import store from '../store';
import { getApiRoute } from '../../config';
import { capitalizeFirstLetter } from '../utility';

const getQueryParams = () => {
  const parsedParams = [];
  const { config } = store.getters;

  const cpvUuid = `cpvUuid=${config.productId}`;
  const product = `product=${config.productFqn}`;
  parsedParams.push(cpvUuid, product);

  return `?count=10&${parsedParams.filter((param) => param !== '').join('&')}`;
};

const getPreviousResults = async (gameName) => {
  return gameName === 'crashcash' ? await getPreviousResultsCrashCash() : await getPreviousNGSResults(gameName);
};
const getPreviousResultsCrashCash = async () => {
  const { config } = store.getters;
  const results = await http.get(
    `${getApiRoute('historyURL', config)}${getApiRoute('gameResults', config)}${config.productInstanceUuid}`,
    {
      params: {
        size: 20,
      },
    },
  );

  results.data = formatPreviousResultsTime(results.data.slice(0, 10));
  return { results: results.data, game: 'CrashCash' };
};
const getPreviousNGSResults = async (gameName) => {
  const { config } = store.getters;
  const results = await http.get(
    `${getApiRoute('historyURL', config)}${getApiRoute('gameResults', config)}${getQueryParams()}`,
  );
  return formatNGSResultsData(results.data, gameName);
};
const formatNGSResultsData = (results, gameName) => {
  const localGameConfig = store.getters.getGameGetter({ specificGame: gameName, getter: 'localGameConfig' });
  const { bettingInputs } = localGameConfig;
  const activeSpecialBets = bettingInputs.filter((bettingInput) => {
    return bettingInput.enabled && bettingInput.betType === 'special';
  });

  switch (gameName) {
    case 'luckysix': {
      results.forEach((result) => {
        result.draw = Object.values(result.draw);
        const specialBets = [];
        activeSpecialBets.forEach((activeSpecialBet) => {
          const specialBet = {
            name: store.getters.getTranslation(activeSpecialBet.betDisplayName),
            value: capitalizeFirstLetter(calculateSpecialBetValue(activeSpecialBet, result)),
          };
          specialBets.push(specialBet);
        });
        const colorBet = {};
        result.specialBets = specialBets;
        result.colorBet = colorBet;
      });
      return results;
    }
    default: {
      // For races
      const productName = store.state.sevenProductsMapper[gameName];
      const data = { previousRounds: results, game: productName };
      data[productName] = { previousRounds: results };
      return data;
    }
  }
};
const calculateSpecialBetValue = (specialBet, result) => {
  const firstNumber = result.draw[0];
  let sum = 0;
  for (let i = 0; i < 5; i += 1) {
    sum += result.draw[i];
  }
  switch (specialBet.betId) {
    case 5: {
      const index = firstNumber % 8 === 0 ? 7 : (firstNumber % 8) - 1;
      return specialBet.outcomes[index];
    }
    case 6: {
      return sum > 122.5 ? specialBet.outcomes[1] : specialBet.outcomes[0];
    }
    case 7: {
      return specialBet.outcomes[firstNumber % 2];
    }
    case 8: {
      return firstNumber > 24 ? specialBet.outcomes[1] : specialBet.outcomes[0];
    }
    case 10: {
      return firstNumber;
    }
    case 11: {
      return specialBet.outcomes[sum % 2];
    }
  }
};

const formatPreviousResultsTime = (results) => {
  results.forEach((result) => {
    // format startedAt to a date object to print correct time
    // eslint-disable-next-line no-param-reassign
    result.startedAt = new Date(result.startedAt);
  });
  return results;
};
export default {
  getPreviousResults,
  formatPreviousResultsTime,
};
