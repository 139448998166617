<template>
  <transition name="loader-wrapper">
    <div class="loader-content centered">
      <div class="loader" />
    </div>
  </transition>
</template>
<script>
export default {
  name: 'GameLoader',
};
</script>

<style lang="scss">
.loader-content {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background-color: var(--black-opacity-60);
  .loader {
    width: 2.5rem;
    height: 2.5rem;
    border: 2px solid var(--white-opacity-20);
    border-left-color: white;
    border-radius: 50%;
    animation: spinner 1s infinite linear;
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
