import http from './http';
import store from '../store';
import { getApiRoute } from '../../config';

const getQueryParams = (params) => {
  const parsedParams = [];
  const {
    config: { productId },
  } = store.state[params.productName];

  const cpvUuid = `cpvUuid=${productId}`;
  const uuid = params.requestId ? `uuid=${params.requestId}` : '';
  const barcode = params.barcode ? `id=${params.barcode}` : '';

  parsedParams.push(cpvUuid, uuid, barcode);

  return `?${parsedParams.filter((param) => param !== '').join('&')}`;
};

const payinNewTicket = async (data) => {
  const { config } = store.getters;
  const response = await http.post(`${getApiRoute('baseURL', config)}${getApiRoute('ticketPayin', config)}`, data);
  return response.data;
};
const payoutNewTicket = async (data) => {
  const { config } = store.getters;
  const response = await http.patch(`${getApiRoute('baseURL', config)}${getApiRoute('ticketPayout', config)}`, data);
  return response.data;
};
const cancelNewTicket = async (data) => {
  const { config } = store.getters;
  const response = await http.delete(`${getApiRoute('baseURL', config)}${getApiRoute('ticketCancel', config)}`, {
    data,
  });
  return response.data;
};
const checkNewTicketBarcode = async (barcode, productName) => {
  const { config } = store.getters;
  const response = await http.get(
    `${getApiRoute('baseURL', config)}${getApiRoute('ticketBarcodeCheck', config)}${getQueryParams({ barcode, productName })}`,
  );
  return response.data;
};
const checkNewTicketRequestId = async (requestId, productName) => {
  const config = store.getters.getGameGetter({ specificGame: 'crashcash', getter: 'config' });
  const response = await http.get(
    `${getApiRoute('baseURL', config)}${getApiRoute('ticketRequestIdCheck', config)}${getQueryParams({
      requestId,
      productName,
    })}`,
  );
  return response.data;
};

const checkNGSTicketBarcode = async (barcode) => {
  const config = store.getters.getGameGetter({ getter: 'config' });
  const response = await http.get(
    `${getApiRoute('baseURL', config)}${getApiRoute('ticketBarcodeCheck', config)}${barcode.toUpperCase()}.json`,
  );
  return response.data;
};
const checkNGSTicketRequestId = async (requestId, productName) => {
  const config = store.getters.getGameGetter({ getter: 'config', specificGame: productName });
  const product = store.state.sevenProductsMapper[productName];
  const response = await http.get(
    `${getApiRoute('baseURL', config)}${getApiRoute(
      'ticketRequestIdCheck',
      config,
    )}${requestId}/product/${product}/check.json`,
  );
  return response.data;
};
const checkTicketBarcode = async (barcode, ticketType, productName = null) => {
  return ticketType === 'newTicket'
    ? await checkNewTicketBarcode(barcode, productName)
    : await checkNGSTicketBarcode(barcode);
};
const checkTicketRequestId = async (requestId, ticketType, productName) => {
  return ticketType === 'newTicket'
    ? await checkNewTicketRequestId(requestId, productName)
    : await checkNGSTicketRequestId(requestId, productName);
};
export default {
  payinNewTicket,
  payoutNewTicket,
  cancelNewTicket,
  checkTicketBarcode,
  checkTicketRequestId,
};
