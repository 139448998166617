<template>
  <div class="template">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'TemplateDefault',
};
</script>

<style scoped lang="scss">
.template {
  height: 100%;
  background: var(--grey);
}
</style>
