<template>
  <div id="app" @contextmenu.prevent>
    <GameLoader v-if="showLoader" />
    <ErrorPage v-else-if="showErrorPage" :description="errorMessage" />
    <TemplateDefault v-else />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import GameLoader from '@/components/GameLoader';
import TemplateDefault from '@/layouts/TemplateDefault';
import ErrorPage from './components/ErrorPage';
export default {
  name: 'App',
  components: {
    ErrorPage,
    GameLoader,
    TemplateDefault,
  },
  computed: {
    ...mapGetters(['isGameActive', 'errorMessage']),
    ...mapState(['currentActiveGame', 'gamesState', 'isAppLoaded']),
    gameData() {
      return this.gamesState[this.currentActiveGame];
    },
    isGameLoaded() {
      return this.gameData && this.gameData.configLoaded && this.gameData.busLoaded;
    },
    isGameLoading() {
      return this.gameData && this.gameData && this.gameData.loading;
    },
    showLoader() {
      return (!this.isAppLoaded && this.isGameActive) || this.isGameLoading;
    },
    showErrorPage() {
      return (!this.isAppLoaded || !this.isGameLoaded) && !this.isGameLoading;
    },
  },
  mounted() {
    if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
      // eslint-disable-next-line no-console
      console.log('Store: ', this.$store);
    }
    document.documentElement.setAttribute('theme', 'dark');
  },
};
</script>

<style lang="scss">
@use './styles/global.scss';
*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none;
  outline: none;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: var(--dark-grey);
  text-align: center;
}

#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
