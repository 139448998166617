<template>
  <div class="error-container">
    <div class="message-container">
      <p class="message-title">{{ title }}</p>
      <p class="message-description">{{ description }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ErrorPage',
  components: {},
  props: {
    title: {
      type: String,
      required: false,
      default: 'Failed to load the game. See error message below for more details.',
    },
    description: {
      type: String,
      required: false,
      default: 'Something went wrong',
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.error-container {
  --text-primary-1: hsl(0, 0%, 94%);
  --background-light: hsl(210, 4%, 18%);
  --button: hsl(212, 80%, 42%);
  --button-border-radius: 4px;
}
.error-container {
  background-color: var(--background-light);
  height: 100%;
  width: 100%;
  color: var(--text-primary-1);
  display: flex;
  justify-content: center;
  padding-top: 12rem;
  .message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .message-title {
      font-size: 22px;
    }
    .message-description {
      font-size: 16px;
      text-align: center;
      margin-top: 16px;
    }
    .button {
      margin-top: 14px;
      max-width: 200px;
      padding: 4px 8px;
    }
  }
}
</style>
