import { createApp } from 'vue';
import App from './App';
import router from './router';
import store from './store';
import { sentry, busService } from './utility';
import { slave as Gateway } from '@nsftx/seven-gravity-gateway';
import Retail from '@nsftx/seven-gravity-gateway/plugin-retail';

const app = createApp({
  extends: App,
});

if (process.env.VUE_APP_SENTRY_ACTIVE === 'true') {
  sentry.start(app);
}

// LISTENING TO ALL PARENT MESSAGES
// TODO CONSIDER ADDING ORIGIN
window.addEventListener('message', (event) => {
  // Slave.Load message can occur before bus init
  if (!event.data || event.data.plugin !== 'GravityGateway') return;
  if (event.data.action === 'Slave.Load') {
    store.dispatch('handleParentMessageLoad', event.data);
  }
});

// All mesages except for Slave.Load go through bus service
window.addEventListener('Bus:Message', (event) => {
  const { activeProducts, globalEvents } = store.state;
  const subscriptionId = event.detail?.subscriptionId;
  if (globalEvents.some((globalEvent) => globalEvent === event.detail.eventName)) {
    store.dispatch('handleBusMessages', event.detail);
  } else {
    if (subscriptionId) {
      const game = activeProducts.filter((product) => product.productId === subscriptionId)[0]?.productName;
      store.dispatch('runAction', { action: 'handleBusMessages', payload: event.detail, activeGame: game });
    }
  }
});
// Prevent default behavior for all our registered shortcuts
window.addEventListener('keydown', (event) => {
  const key = event.ctrlKey ? `ctrl + ${event.key.toLowerCase()}` : event.key.toLowerCase();
  if (store.state.preventDefaultKeys?.includes(key)) {
    event.preventDefault();
  }
});

// Disconnect game before closing
window.addEventListener('beforeunload', () => {
  busService.stop('crashcash', 'Session completed successfully');
});

(async () => {
  new Gateway({
    isDebug: true,
    slaveId: 'Games',
    eventPropagation: {
      click: true,
      keydown: '*',
    },
    eventListeners: {
      keydown: ['9', '13', '49', '50', '51', '52', '54', '97', '100', '102', '106', '107', '111', '191'],
    },
    plugins: [new Retail()],
  });

  app.use(router).use(store).mount('#app');
})();
